import React from "react";
import "./Footer.css";
import { BsRCircle, BsTelegram, BsTwitter } from "react-icons/bs";
import logo from "../Assets/logo.png";

export default function Footer() {
  return (
    <div className="main_footer">
      <div className="container">
        <div className="row">
          <div className="col-2 ">
            <img src={logo} className=" logo_adjsutment" alt="" />
          </div>

          {/* <div className="col-md-3">
            <a className="footer_links" href="https://docs.intelliquantcoin.com/" target="_blank">
              <li>What is BRW?</li>
            </a>
            <a className="footer_links" href="https://docs.intelliquantcoin.com/how-to-buy" target="_blank">
              <li>How to Buy</li>
            </a>
            <a className="footer_links" href="https://docs.intelliquantcoin.com/roadmap" target="_blank">
              <li>Roadmap</li>
            </a>
            <a className="footer_links" href="https://docs.intelliquantcoin.com/usdinqu-vs-competition" target="_blank">
              <li>COMPARISONS</li>
            </a>
            <a className="footer_links" href="https://docs.intelliquantcoin.com/faq" target="_blank">
              <li>FAQ</li>
            </a>
          </div> */}
          {/* <div className="col-md-3">
            <a className="footer_links pt-0" href="">
              <li>Terms of Service</li>
            </a>
            <a className="footer_links" href="">
              <li>Privacy Policy</li>
            </a>
            <a className="footer_links" href="">
              <li>Cookies</li>
            </a>
          </div> */}
          <div className="col-md-9 text-md-end text-start">
            {/* <p className="footer_links">International Business</p> */}
            {/* <p className="footer_links">Centre, Rue du Gabian,</p> */}
            {/* <p className="footer_links">98000 Monaco</p> */}
            {/* <p className="footer_links">contact@bitcoinbsc.io</p> */}

            <div className="mt-5">

                <h1 className="heading_footer">Welcome to Bitrock Wallet Token Staking</h1>
              <p className="footer_links d-flex ">
                Stake now $BRW Token to earn share of revenue generated by the
                Bitrock Wallet from Transaction done in-wallet. 60% of Fee
                Revenue generated will be converted to $WBROCK and added to the
                Staking pool every week for all $BRW Staker to earn them. A lock
                period of 14 days applies , during which 25% penalty is applied
                for early unstake . Your stake remains staked unless manually
                unstaked <br></br>
                NOTE: APY is not Fixed but expect it to be high always
                as more transaction done in Bitrock Wallet , the more revenue
                generated and more $WBROCK added to Staking pool every week.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          {/* <div className="lower">
            <p>
              Disclaimer: Cryptocurrencies are volatile and could go down as
              well as up in value. Profits may be subject to capital gains or
              other taxes applicable in your jurisdiction. Always do your own
              research and only invest what you can afford to lose.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
